
import { defineComponent } from 'vue';
import { ShipShop } from '@/interfaces/ingame/shipShop';

export default defineComponent({
    name: 'ShipShopListItem',
    props: {
        shipShop: {
            type: Object,
            required: true,
        },
    },
    computed: {
        typedShipShop(): ShipShop {
            return this.shipShop as ShipShop;
        },
        imageStyle(): Record<string, string> {
            return {
                backgroundImage: this.typedShipShop && this.typedShipShop.imageUri ? `url('${this.typedShipShop.imageUri}')` : '',
            };
        },
    },
});
